import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton, Input, Textarea, Wrap, WrapItem , Heading, Divider} from "@chakra-ui/react";

import emailjs from "emailjs-com"
import Colors from "../constants/Colors";
import Card from "./Card2"

import { withTranslation } from 'react-i18next';

import background from '../images/background_1.jpg'

class HeaderBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            company: "",
            message: ""
        }
    };

    _handleSendMessage() {
        console.log("click")
        {/*emailjs.send('service_y3a8mrg', 'template_iyfmrdh', ({form_name: this.state.name, form_email: this.state.email, form_company: this.state.company, form_message: this.state.message}), 'user_zNrwyghQxeuCl5d4YgKOg').then(res => {
            console.log("Email enviado.")
        }).catch((err) => {
            console.log('Error al enviar mail: ' + err)
        })*/}
        
    }

    render() {
        return (
            <Box maxW="100%" overflow="hidden" d="flex" justifyContent="center">
                <Box w="1140px" p="16px" py={"32px"} bg="" style={{position: 'relative', top: 0, left: 0, bottom: 0, right: 0}}>
                    <Flex direction="column" alignItems="right" mb="30px">
                        <Heading as="h1" size="xl" textAlign="right" mr="12px" color={Colors.text.title}>{this.props.t('tractament.title')}</Heading>
                        <Text fontSize="lg" textAlign="right" mt="10px" color={Colors.text.subtitle}>{this.props.t('tractament.description')}</Text>
                    </Flex>
                    <Flex direction="column" alignItems="center">
                        <Flex direction={{base: "column", md: "row"}}>
                            <Card 
                                mr={{base: "column", md: "30px"}}
                                title={this.props.t('tractament.box1_title')}
                                description={this.props.t('tractament.box1_description')}
                                item1={this.props.t('tractament.box1_text1')}
                                item2={this.props.t('tractament.box1_text2')}
                                item3={this.props.t('tractament.box1_text3')}
                                item4={this.props.t('tractament.box1_text4')}
                                time={"50 min."}
                            />
                            <Card
                                title={this.props.t('tractament.box2_title')}
                                description={this.props.t('tractament.box2_description')}
                                item1={this.props.t('tractament.box2_text1')}
                                item2={this.props.t('tractament.box2_text2')}
                                item3={this.props.t('tractament.box2_text3')}
                                item4={this.props.t('tractament.box2_text4')}
                                time={"50 min."}
                            />
                        </Flex>
                    </Flex>
                    <Flex direction="column" alignItems="center" w="full" mt="80px">
                        <Divider w="75%"/>
                    </Flex>
                </Box>
            </Box>
        );
    }
}
export default withTranslation()(HeaderBox);
