import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton, Input, Textarea, Wrap, WrapItem, Heading, HashLink } from "@chakra-ui/react";

class HeaderBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            company: "",
            message: ""
        }
    };

    _handleSendMessage() {
        console.log("click3")
    }

    render() {
        return (
            <Box maxW="100%" overflow="hidden" d="flex" justifyContent="center">
                <Box w="1140px" h="600px" p="36px" bg="">
                    
                </Box>
            </Box>
        );
    }
}
export default HeaderBox;
