import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton, Input, Textarea, Wrap, WrapItem , Heading, Center} from "@chakra-ui/react";

import { withTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import profile1 from '../images/user1.jpeg';
import profile2 from '../images/user2.jpeg';
import profile3 from '../images/user3.jpeg';
import profile4 from '../images/user4.jpeg';
import profile5 from '../images/user5.jpeg';

import Colors from "../constants/Colors";

class HeaderBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            company: "",
            message: ""
        }
    };

    _handleSendMessage() {
        console.log("click3")
    }

    render() {
        return (
            <Box maxW="100%" overflow="hidden" d="flex" justifyContent="center" mb="60px" mt="0px">
                <Box w="1140px" p="16px" py={"32px"}>
                    <Flex direction="column" alignItems='center'>
                        <Heading as="h1" size="xl" color={Colors.text.title} textAlign="center">{this.props.t('equip.title')}</Heading>
                        <Text fontSize="xl" mt="10px" mb="40px" color={Colors.text.subtitle} maxW="700px" textAlign="center">{this.props.t('equip.description')}</Text>
                    </Flex>

                    <Wrap spacing="30px" justify="center">
                        <WrapItem>
                            <Box w="300px" mb="30px">
                                <Box w="300px" h="200px" bg="rgba(0,0,0,0.02)" borderRadius={20} bgImage={profile1} backgroundPosition="center" bgSize='cover' backgroundRepeat="no-repeat">
                                    {/*<Background/>*/}
                                </Box>
                                <Heading as="h1" size="md" isTruncated color={Colors.text.title} textAlign="center" mt="20px" mb="10px">Anna Tàpias</Heading>
                                <Text fontSize="md" color={Colors.text.subtitle} textAlign="center">{this.props.t('equip.description1')}</Text>
                            </Box>
                        </WrapItem>
                        <WrapItem>
                            <Box w="300px" mb="30px">
                                <Box w="300px" h="200px" bg="rgba(0,0,0,0.02)" borderRadius={20} bgImage={profile2} backgroundPosition="center" bgSize='cover' backgroundRepeat="no-repeat">
                                </Box>
                                <Heading as="h1" size="md" isTruncated color={Colors.text.title} textAlign="center" mt="20px" mb="10px">Marina De Moragas</Heading>
                                <Text fontSize="md" color={Colors.text.subtitle} textAlign="center">{this.props.t('equip.description2')}</Text>
                            </Box>
                        </WrapItem>
                        <WrapItem>
                            <Box w="300px" mb="30px">
                                <Box w="300px" h="200px" bg="rgba(0,0,0,0.02)" borderRadius={20} bgImage={profile3} backgroundPosition="center" bgSize='cover' backgroundRepeat="no-repeat">
                                </Box>
                                <Heading as="h1" size="md" isTruncated color={Colors.text.title} textAlign="center" mt="20px" mb="10px">Diana Calderón</Heading>
                                <Text fontSize="md" color={Colors.text.subtitle} textAlign="center">{this.props.t('equip.description3')}</Text>
                            </Box>
                        </WrapItem>
                        <WrapItem>
                            <Box w="300px" mb="30px">
                                <Box w="300px" h="200px" bg="rgba(0,0,0,0.02)" borderRadius={20} bgImage={profile4} backgroundPosition="center" bgSize='cover' backgroundRepeat="no-repeat">
                                </Box>
                                <Heading as="h1" size="md" isTruncated color={Colors.text.title} textAlign="center" mt="20px" mb="10px">Laia Garcés</Heading>
                                <Text fontSize="md" color={Colors.text.subtitle} textAlign="center">{this.props.t('equip.description4')}</Text>
                            </Box>
                        </WrapItem>
                        <WrapItem>
                            <Box w="300px">
                                <Box w="300px" h="200px" bg="rgba(0,0,0,0.02)" borderRadius={20} bgImage={profile5} backgroundPosition="center" bgSize='cover' backgroundRepeat="no-repeat">
                                </Box>
                                <Heading as="h1" size="md" isTruncated color={Colors.text.title} textAlign="center" mt="20px" mb="10px">Maria Gracia</Heading>
                                <Text fontSize="md" color={Colors.text.subtitle} textAlign="center">{this.props.t('equip.description5')}</Text>
                            </Box>
                        </WrapItem>
                    </Wrap>

                </Box>
            </Box>
        );
    }
}
export default withTranslation()(HeaderBox);
