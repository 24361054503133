import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton, Input, Textarea, Wrap, WrapItem } from "@chakra-ui/react";

import emailjs from "emailjs-com"

class HeaderBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            company: "",
            message: ""
        }
    };

    _handleSendMessage() {
        console.log("click")
        {/*emailjs.send('service_y3a8mrg', 'template_iyfmrdh', ({form_name: this.state.name, form_email: this.state.email, form_company: this.state.company, form_message: this.state.message}), 'user_zNrwyghQxeuCl5d4YgKOg').then(res => {
            console.log("Email enviado.")
        }).catch((err) => {
            console.log('Error al enviar mail: ' + err)
        })*/}
        
    }

    render() {
        return (
            <Box maxW="100%" overflow="hidden" d="flex" justifyContent="center">
                <Box w="1140px" p="16px" py={"32px"} bg="blue">
                    <Box d={{base: null, md: 'flex'}}>
                        <Box flex={{base: null, md: 1}} w={{base: '100%'}} mr={{base: 0, md: '40px'}}>
                            <Text mb="10px">Nom: </Text>
                            <Input
                                placeholder="John Doe"
                                onChange={(event) => this.setState({name: event.target.value})}
                            />
                        </Box>
                        <Box flex={{base: null, md: 1}} w={{base: '100%'}} mr={{base: 0, md: '40px'}} mt={{base: '26px', md: 0}}>
                            <Text mb="10px">Correu: </Text>
                            <Input 
                                placeholder="johndoe@example.com"
                                onChange={(event) => this.setState({email: event.target.value})}
                            />
                        </Box>
                        <Box flex={{base: null, md: 1}} w={{base: '100%'}} mt={{base: '26px', md: 0}}>
                            <Text mb="10px">Empresa: </Text>
                            <Input 
                                placeholder="John Doe S.L."
                                onChange={(event) => this.setState({company: event.target.value})}
                            />
                        </Box>
                    </Box>
                    <Box mt="36px">
                        <Text mb="10px">Missatge: </Text>
                        <Textarea 
                            placeholder="Escriu el missatge..."
                            onChange={(event) => this.setState({message: event.target.value})}
                        />
                    </Box>
                    <Box d="flex" justifyContent="center" mt="36px">
                        <Button 
                            w={'200px'}
                            colorScheme="blue"
                            variant="outline"
                            onClick={() => this._handleSendMessage()}
                        >
                            Enviar missatge
                        </Button>
                    </Box>
                </Box>
            </Box>
        );
    }
}
export default HeaderBox;
