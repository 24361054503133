const title = '#46465c';

export default {
  main: {
    green: '#7cbc64',
    greenHover: '#6ca856',
    greenLight: '#afdea2'
  },
  text: {
    title: '#203D46',
    subtitle: '#6F929E',
    content: ''
  }
};