import React from 'react';
import {ChakraProvider, Box, Text, VStack, Code, Grid, Heading, Container, Flex, Center, Wrap, WrapItem, Image, Spacer, extendTheme} from '@chakra-ui/react';

import { Switch, Route, Redirect, MemoryRouter } from 'react-router-dom';

import { createBreakpoints } from "@chakra-ui/theme-tools"

import HomeScreen from './screens/HomeScreen';
import MailFormBox2 from './components/MailFormBox2';

function App() {
	return (
		<ChakraProvider>
			<HomeScreen/>
		</ChakraProvider>
	);
}

export default App;

