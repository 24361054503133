import React, { Component } from 'react';
import { Box, Menu, MenuButton, MenuItem, MenuList, Flex, Button, Text } from '@chakra-ui/react';
import { FiChevronDown, FiGlobe } from 'react-icons/fi';
import Colors from '../../constants/Colors';

import i18n from '../../i18n';

class IdiomaSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            idioma: i18n.language,
            idiomasDic: {
                "CAT": "",
                "ESP": "",
                "ENG": "",
            },
            idiomas: [ "cat", "esp", "eng" ]
        }
    };

    _onChangeLanguaje(languaje){
		i18n.changeLanguage(languaje, () => {
		    this.setState({idioma: languaje})
		});
	}

    render() {
        return (
            <Menu display={{ base: 'none', md: 'flex' }}>
                <MenuButton
                    as={Button}
                    minW={0}
                    ml={this.props.ml ? this.props.ml : "26px"}
                    pl={{base: "20px", md: "10px"}}
                    pr={{base: "16px", md: "6px"}}
                    shadow={this.props.shadow ? this.props.shadow : "none"}
                    borderWidth={"1px"}
                    borderColor={"#000000" + "0B"}
                    bg={this.props.bg ?? "transparent"}
                    maxH={{base: "50px", md: "30px"}}
                    _hover={{bg: Colors.main.greenLight}}
                    _active={{bg: Colors.main.greenLight}}
                    transition="all 0.5s cubic-bezier(.08,.52,.52,1)"
                    borderRadius="6px"
                    style={{webkitTapHighlightColor: "transparent"}}
                    _focus={{
                        boxShadow:
                            "0 0 0px 2px " + Colors.main.greenLight + ", 0 1px 1px " + Colors.main.greenLight,
                    }}
                >
                    <Flex direction="row" alignItems="center" justifyContent="center">
                        <Box mr="5px" ml={{base: "0px", md: "7px"}} mt="2px" display={{base: "flex", md: "none"}}>
                            <FiGlobe size="12px" color={Colors.text.title}/>
                        </Box>
                        {this.state.idioma == "esp" ?
                            <Text mr="12px" fontWeight={400} fontSize="14px" color={Colors.text.title} mt="2px">ESP</Text>
                        : this.state.idioma == "eng" ?
                            <Text mr="12px" fontWeight={400} fontSize="14px" color={Colors.text.title} mt="2px">ENG</Text>
                        : this.state.idioma == "cat" ?
                            <Text mr="12px" fontWeight={400} fontSize="14px" color={Colors.text.title} mt="2px">CAT</Text>
                        : 
                            <Text mr="12px" fontWeight={400} fontSize="14px" color={Colors.text.title} mt="2px">CAT</Text>
                        }
                        <FiChevronDown  color={"#000000" + "2B"}/>
                    </Flex>
                </MenuButton>
                <MenuList zIndex={2} borderRadius="6px" shadow="none" overflow="hidden" p={0} minW="0" w={{base: '112px', md: '73px'}} bg={Colors.main.skin} borderColor={"#000000" + "0B"}>
                    <MenuItem 
                        transition="all 0.5s cubic-bezier(.08,.52,.52,1)" 
                        _hover={{bg: Colors.main.greenLight}} 
                        _active={{bg: Colors.main.greenLight + "3E"}} 
                        _focus={{bg: Colors.main.greenLight + "3E"}}
                        onClick={() => this._onChangeLanguaje(this.state.idioma)}
                    >
                        {this.state.idiomasDic[this.state.idioma.toUpperCase()]} 
                        <Text color={Colors.text.title} fontSize="14px" fontWeight="400">&nbsp;{this.state.idioma.toUpperCase()}</Text>
                    </MenuItem>
                    {this.state.idiomas.map((idiomaItem) => (
                        idiomaItem != this.state.idioma ? 
                            <MenuItem
                                transition="all 0.5s cubic-bezier(.08,.52,.52,1)" 
                                _hover={{bg: Colors.main.greenLight + "3E"}} 
                                _active={{bg: Colors.main.greenLight + "3E"}} 
                                _focus={{bg: Colors.main.greenLight + "3E"}}
                                onClick={() => this._onChangeLanguaje(idiomaItem)}
                            >
                                {this.state.idiomasDic[idiomaItem.toUpperCase()]} 
                                <Text fontSize="14px" color={Colors.text.title} fontWeight="400">&nbsp;{idiomaItem.toUpperCase()}</Text>
                            </MenuItem>
                        :
                            null
                    ))}
                </MenuList>
            </Menu>
        );
    }
}
export default IdiomaSelector;