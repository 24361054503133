import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton, Input, Textarea, Wrap, WrapItem , Heading} from "@chakra-ui/react";

import emailjs from "emailjs-com"
import Colors from "../constants/Colors";
import Card from "./Card"

import { withTranslation } from 'react-i18next';

import { AiTwotoneHeart } from "react-icons/ai";
import { BsFillHeartFill, BsJustifyLeft } from "react-icons/bs";

import background from '../images/background_1.jpg'

class HeaderBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            company: "",
            message: ""
        }
    };

    _handleSendMessage() {
        console.log("click")
        {/*emailjs.send('service_y3a8mrg', 'template_iyfmrdh', ({form_name: this.state.name, form_email: this.state.email, form_company: this.state.company, form_message: this.state.message}), 'user_zNrwyghQxeuCl5d4YgKOg').then(res => {
            console.log("Email enviado.")
        }).catch((err) => {
            console.log('Error al enviar mail: ' + err)
        })*/}
        
    }

    render() {
        return (
            <Box maxW="100%" overflow="hidden" d="flex" justifyContent="center" bg={'rgba(0,0,0,0.5)'} bgImage={background} backgroundPosition="center" bgSize='cover' bgAttachment="fixed" backgroundRepeat="no-repeat" mt="80px">
                <Box w="full" bg={"rgba(123, 188, 100, 0.8)"} d="flex" justifyContent="center">
                <Box w="1140px" p="16px" py={"32px"} bg="" style={{position: 'relative', top: 0, left: 0, bottom: 0, right: 0}}>
                    <Flex direction="column" alignItems="center">
                        <Heading as="h1" size="xl" color={Colors.text.title} textAlign="center" color="white">{this.props.t('serveis.title')}</Heading>
                        <Text fontSize="xl" mt="10px" color={"white"} textAlign="center">{this.props.t('serveis.description')}</Text>
                        <Flex direction={{base: "column", md: "row"}}>
                            <Card 
                                mr={{base: "column", md: "30px"}}
                                title={this.props.t('serveis.box1_title')}
                                description={this.props.t('serveis.box1_description')}
                                link="diagnostic"
                                icon={<Box mt="0px"><BsJustifyLeft fill={Colors.main.green} size="100px"/></Box>}
                                offsetbase={-20}
                                offsetmd={50}
                                buttonText={this.props.t('serveis.boton')}
                            />
                            <Card
                                title={this.props.t('serveis.box2_title')}
                                description={this.props.t('serveis.box2_description')}
                                link="tractament"
                                icon={<Box mt="20px"><BsFillHeartFill fill={Colors.main.green} size="80px"/></Box>}
                                offsetbase={-80}
                                buttonText={this.props.t('serveis.boton')}
                            />
                        </Flex>
                    </Flex>

                </Box>
                </Box>
            </Box>
        );
    }
}
export default withTranslation()(HeaderBox);
