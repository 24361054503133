import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton, Input, Textarea, Wrap, WrapItem , Heading, useToast} from "@chakra-ui/react";

import { withTranslation } from 'react-i18next';
import emailjs from "emailjs-com"
import Colors from "../constants/Colors";

import background from '../images/background_1.jpg'

import BotonEnviarMail from './BotonEnviarMail'

class HeaderBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            message: "",
        }
    };

    async _handleSendMessageOLD() {
        console.log("click")
        {/*emailjs.send('service_y3a8mrg', 'template_iyfmrdh', ({form_name: this.state.name, form_email: this.state.email, form_company: this.state.company, form_message: this.state.message}), 'user_zNrwyghQxeuCl5d4YgKOg').then(res => {
            console.log("Email enviado.")
        }).catch((err) => {
            console.log('Error al enviar mail: ' + err)
        })*/}

        /*let transporter = nodemailer.createTransport({
            host: "smtp.dondominio.com",
            port: 587,
            secure: false, // true for 465, false for other ports
            auth: {
                user: "prueba@tickb.it", // generated ethereal user
                pass: "rSkGoF@Sa2HS", // generated ethereal password
            },
        });

        // send mail with defined transport object
        let info = await transporter.sendMail({
            from: '"' + this.state.name + '" <' + this.state.email + '>', // sender address
            to: "contact@albertgranados.com, contact@albertgranados.com", // list of receivers
            subject: "[Contacte for] Reeducació", // Subject line
            text: "Hello world?", // plain text body
            html: "", // html body
        });

        console.log("Message sent: %s", info.messageId);
        // Message sent: <b658f8ca-6296-ccf4-8306-87d57a0b4321@example.com>

        // Preview only available when sending through an Ethereal account
        console.log("Preview URL: %s", nodemailer.getTestMessageUrl(info));*/


    }

    _handleSendMessage(){
        /*fetch('https://agitated-sammet-3cc0b7.netlify.app/.netlify/functions/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: '{"name": "Albert Granados", "email": "albertgranro@gmail.com", "message": "Hola! Esto es una prueba"}'
        });*/
        //execMailSender(this.state.name, this.state.email, this.state.message);
        this.showOkToast();
        /*fetch('https://agitated-sammet-3cc0b7.netlify.app/.netlify/functions/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain'
            },
            body: JSON.stringify({name: this.state.name, email: this.state.email, message: this.state.message})
        });*/
    }

    showOkToast() {
        /*this.state.toast({
            title: 'Account created.',
            description: "We've created your account for you.",
            status: 'success',
            duration: 9000,
            isClosable: true,
        })*/
    }

    render() {
        return (
            <Box maxW="100%" overflow="hidden" d="flex" justifyContent="center" bg={'rgba(0,0,0,0.5)'} bgImage={background} backgroundPosition="center" bgSize='cover' bgAttachment="fixed" backgroundRepeat="no-repeat">
                <Box w="full" bg={"rgba(123, 188, 100, 0.8)"} d="flex" justifyContent="center">
                <Box w="1140px" p="16px" py={"32px"} bg="" style={{position: 'relative', top: 0, left: 0, bottom: 0, right: 0}}>
                    <Heading as="h1" size="xl" color={Colors.text.title} textAlign="center" color="white" mb="40px">{this.props.t('contacte.title')}</Heading>

                    <Box d={{base: null, md: 'flex'}}>
                        <Box flex={{base: null, md: 1}} w={{base: 'full'}} mr={{base: 0, md: '40px'}}>
                            <Text mb="10px" color="white" fontSize="md">{this.props.t('contacte.name')}</Text>
                            <Input
                                bg="rgba(255,255,255, 1)"
                                textColor={Colors.text.title}
                                borderWidth="1"
                                borderColor={"rgba(255,255,255, 0.6)"}
                                class="inputForm"
                                placeholder={this.props.t('contacte.name_p')}
                                onChange={(event) => this.setState({name: event.target.value})}
                            />
                        </Box>
                        <Box flex={{base: null, md: 1}} w={{base: '100%'}} mt={{base: '26px', md: 0}}>
                            <Text mb="10px" color="white" fontSize="md">{this.props.t('contacte.mail')}</Text>
                            <Input 
                                bg="rgba(255,255,255, 1)"
                                textColor={Colors.text.title}
                                borderWidth="1"
                                borderColor={"rgba(255,255,255, 0.6)"}
                                placeholder={this.props.t('contacte.mail_p')}
                                onChange={(event) => this.setState({email: event.target.value})}
                            />
                        </Box>
                    </Box>
                    <Box mt="36px">
                        <Text mb="10px" color="white" fontSize="md">{this.props.t('contacte.message')}</Text>
                        <Textarea 
                            bg="rgba(255,255,255, 1)"
                            textColor={Colors.text.title}
                            class="inputForm"
                            borderWidth="1"
                            borderColor={"rgba(255,255,255, 0.6)"}
                            placeholder={this.props.t('contacte.message_p')}
                            onChange={(event) => this.setState({message: event.target.value})}
                        />
                    </Box>
                    {/*<Box d="flex" justifyContent="center" mt="36px">
                        <Flex as="button" onClick={() => this._handleSendMessage()} bg="rgba(255,255,255,0.2)" direction="row" transition="all 0.5s cubic-bezier(.08,.52,.52,1)" _hover={{bg: 'rgba(255,255,255,0.35)', transform: "scale(1.02)"}} borderRadius="10px" overflow="hidden" alignItems="center" pl="20px" pr="20px" pt="10px" pb="10px">
                            <Text color={"white"} fontWeight="bold" fontSize="lg">{this.props.t('contacte.button')}</Text>
                        </Flex>
                    </Box>*/}
                    <BotonEnviarMail 
                        button_text={this.props.t('contacte.button')}
                        success_alert_title={this.props.t('contacte.success_alert_title')}
                        success_alert_description={this.props.t('contacte.success_alert_description')}
                        loading_alert_title={this.props.t('contacte.loading_alert_title')}
                        loading_alert_description={this.props.t('contacte.loading_alert_description')}
                        error_alert_title={this.props.t('contacte.error_alert_title')}
                        error_alert_description={this.props.t('contacte.error_alert_description')}
                        name={this.state.name}
                        email={this.state.email}
                        message={this.state.message}
                    />
                </Box>
                </Box>
            </Box>
        );
    }
}
export default withTranslation()(HeaderBox);
