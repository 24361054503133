import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton, Input, Textarea, Wrap, WrapItem , Heading, Divider} from "@chakra-ui/react";

import {Link, scroller} from "react-scroll";

import { withTranslation } from 'react-i18next';

import thinking from '../images/thinking.png';
import flecha from '../images/arrow.png';

import brain from '../images/brain.png';
import wave from '../images/wave.png';
import geometric from '../images/geometric.png';

import Colors from "../constants/Colors";

class HeaderBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            company: "",
            message: ""
        }
    };

    _handleClick() {
        console.log("click")
    }

    render() {
        return (
            <Box maxW="100%" overflow="hidden" d="flex" justifyContent="center" mt={{base: '80px', md: 0}}>
                <Box w="1140px" bg="" mt="60px">
                    <Flex direction={{base: "column", md: "row"}}>
                        <Flex direction="column" pl="36px" pr="36px">
                            <Flex direction="column" alignItems="left">
                                <Heading maxW="550px" as="h1" size="2xl" mr="12px" color={Colors.text.title}>{this.props.t('header.title_part1')} <Text as={'span'} color={Colors.main.green}>{this.props.t('header.title_part2')}</Text> {this.props.t('header.title_part3')}</Heading>
                                <Text maxW="550px" fontSize="xl" mt="40px" color={Colors.text.subtitle}>{this.props.t('header.descripcion')}</Text>
                            </Flex>
                            <Box direction="row" mt="40px" display={{base: 'none', md: 'flex'}}>
                                <a href="#">
                                <Link smooth to={'especialitzacio'} onClick={() => console.log("CAMBIO DE PAGINA")}>
                                    <Flex direction="row">
                                        <Flex direction="row" transition="all 0.5s cubic-bezier(.08,.52,.52,1)" _hover={{bg: Colors.main.greenHover, transform: "scale(1.02)"}} borderRadius="10px" bg={Colors.main.green} overflow="hidden" alignItems="center" pl="20px" pr="20px" pt="10px" pb="10px">
                                            <Text color={"white"} fontWeight="bold" fontSize="lg">{this.props.t('header.boton')}</Text>
                                        </Flex>
                                    </Flex>
                                </Link>
                                </a>
                            </Box>
                            <Box direction="row" mt="40px" display={{base: 'flex', md: 'none'}}>
                                <a href="#">
                                <Link smooth to={'especialitzacio'} spy={true} offset={-30} onClick={() => console.log("CAMBIO DE PAGINA")}>
                                    <Flex direction="row">
                                        <Flex direction="row" transition="all 0.5s cubic-bezier(.08,.52,.52,1)" _hover={{bg: Colors.main.greenHover, transform: "scale(1.02)"}} borderRadius="10px" bg={Colors.main.green} overflow="hidden" alignItems="center" pl="20px" pr="20px" pt="10px" pb="10px">
                                            <Text color={"white"} fontWeight="bold" fontSize="lg">{this.props.t('header.boton')}</Text>
                                        </Flex>
                                    </Flex>
                                </Link>
                                </a>
                            </Box>
                        </Flex>
                        <Image
                            w={{base: '100%', md: '40%'}}
                            h={'100%'}
                            src={thinking}
                            objectFit="cover"
                            mt="30px"
                            pr="36px"
                            pl="36px"
                        />
                    </Flex>


                    <Box position={'absolute'}  display={{base: 'none', md: 'flex'}} ml="40px" mt="20px" width={"120px"} h="150px" bgImage={flecha} backgroundPosition="center" bgSize='cover' backgroundRepeat="no-repeat">

                    </Box>



                    <section id="especialitzacio">
                        <Flex direction="column" pl="36px" pr="36px">
                            <Flex direction="column" alignItems="left" alignItems="center" mt="100px">
                                <Heading maxW="550px" as="h1" size="xl" mr="12px" textAlign="center" color={Colors.text.title}>{this.props.t('header.title2_part1')}
                                <Text
                                    as={'span'}
                                    position={'relative'}
                                    _after={{
                                        content: "''",
                                        width: '95%',
                                        height: '7px',
                                        position: 'absolute',
                                        bottom: 1,
                                        left: 1,
                                        bg: Colors.main.green,
                                        opacity: 0.5,
                                        zIndex: -1,
                                    }}>
                                    {this.props.t('header.title2_part2')}
                                    </Text>
                                </Heading>
                                <Text fontSize="xl" mt="10px" mb="40px" textAlign="center" color={Colors.text.subtitle}>{this.props.t('header.descripcion2')}</Text>
                            </Flex>
                        </Flex>
                        
                        <Flex direction="column" alignItems="center" mb="80px" mt="30px">
                            <Wrap spacing="30px" justify="center">
                                <WrapItem>
                                    <Box w="300px" _hover={{transform: "scale(1.02)"}} transition="all 0.5s cubic-bezier(.08,.52,.52,1)">
                                        <Box d="flex" w="300px" h="200px" bg="rgba(0,0,0,0.02)" borderRadius={20} overflow="hidden" alignItems="center" justifyContent="center">
                                            <Image
                                                w={{base: '100%', md: '100%'}}
                                                h={'70%'}
                                                src={brain}
                                                objectFit="contain"
                                            />
                                        </Box>
                                        <Heading as="h1" size="md" isTruncated color={Colors.text.title} textAlign="center" mt="20px" mb="10px">{this.props.t('header.neuro')}</Heading>
                                        {/*<Text fontSize="md" color={Colors.text.subtitle} textAlign="center">Especialista en diagnòstic i tractament dels trastorns del llenguatge.</Text>*/}
                                    </Box>
                                </WrapItem>
                                <WrapItem>
                                    <Box w="300px" _hover={{transform: "scale(1.02)"}} transition="all 0.5s cubic-bezier(.08,.52,.52,1)">
                                        <Box d="flex" w="300px" h="200px" bg="rgba(0,0,0,0.02)" borderRadius={20} alignItems="center" justifyContent="center">
                                            <Image
                                                w={'70%'}
                                                h={'70%'}
                                                src={wave}
                                                objectFit="contain"
                                            />
                                        </Box>
                                        <Heading as="h1" size="md" isTruncated color={Colors.text.title} textAlign="center" mt="20px" mb="10px">{this.props.t('header.logope')}</Heading>
                                        {/*<Text fontSize="md" color={Colors.text.subtitle} textAlign="center">Especialista en diagnòstic i tractament dels trastorns del llenguatge.</Text>*/}
                                    </Box>
                                </WrapItem>
                                <WrapItem>
                                    <Box w="300px" _hover={{transform: "scale(1.02)"}} transition="all 0.5s cubic-bezier(.08,.52,.52,1)">
                                        <Box d="flex" w="300px" h="200px" bg="rgba(0,0,0,0.02)" borderRadius={20} alignItems="center" justifyContent="center">
                                            <Image
                                                w={'70%'}
                                                h={'50%'}
                                                src={geometric}
                                                objectFit="contain"
                                            />
                                        </Box>
                                        <Heading as="h1" size="md" isTruncated color={Colors.text.title} textAlign="center" mt="20px" mb="10px">{this.props.t('header.psico')}</Heading>
                                        {/*<Text fontSize="md" color={Colors.text.subtitle} textAlign="center">Especialista en diagnòstic i tractament dels trastorns del llenguatge.</Text>*/}
                                    </Box>
                                </WrapItem>
                            </Wrap>
                        </Flex>
                    </section>


                </Box>
            </Box>
        );
    }
}
export default withTranslation()(HeaderBox);
