import {
    Box,
    Center,
    useColorModeValue,
    Heading,
    Text,
    Stack,
    Image,
    Flex,
  } from '@chakra-ui/react';

  import Colors from '../constants/Colors';
  import { HashLink } from 'react-router-hash-link';

  import {Link} from "react-scroll";

  import { BsFillHeartFill } from "react-icons/bs";
  
  const IMAGE =
    'https://images.unsplash.com/photo-1518051870910-a46e30d9db16?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80';
  
  export default function ProductSimple({...props}) {
    return (
      <Center py={6}>
        <Box
          mr={props.mr}
          role={'group'}
          p={6}
          maxW={'400px'}
          h="full"
          w={'full'}
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow={'2xl'}
          rounded={'lg'}
          pos={'relative'}
          zIndex={1}>
          
            <Flex direction="column" alignItems="center">
                <Box d="flex" h="170px" w="170px" bg={"rgba(0,0,0,0.025)"} borderRadius={"100px"} mb="30px" alignItems="center" justifyContent="center">
                  {props.icon}
                </Box>
                <Heading as="h1" size="lg" mr="12px" color={Colors.text.title}>{props.title}</Heading>
                <Text fontSize="md" mt="10px" color={Colors.text.subtitle} textAlign="center">{props.description}</Text>
                <Box direction="row" mt="30px" display={{base: 'none', md: 'flex'}}>
                  <a href="#">
                  <Link smooth to={props.link} spy={true} offset={props.offsetmd ? props.offsetmd : 0}  onClick={() => console.log("CAMBIO DE PAGINA")}>
                      <Flex direction="row">
                          <Flex direction="row" transition="all 0.5s cubic-bezier(.08,.52,.52,1)" _hover={{bg: Colors.main.greenHover, transform: "scale(1.02)"}} borderRadius="10px" bg={Colors.main.green} overflow="hidden" alignItems="center" pl="20px" pr="20px" pt="10px" pb="10px">
                              <Text color={"white"} fontWeight="bold" fontSize="md">{props.buttonText}</Text>
                          </Flex>
                      </Flex>
                  </Link>
                  </a>
                </Box>
                <Box direction="row" mt="30px" display={{base: 'flex', md: 'none'}}>
                  <a href="#">
                  <Link smooth to={props.link} spy={true} offset={props.offsetbase ? props.offsetbase : 0} onClick={() => console.log("CAMBIO DE PAGINA")}>
                      <Flex direction="row">
                          <Flex direction="row" transition="all 0.5s cubic-bezier(.08,.52,.52,1)" _hover={{bg: Colors.main.greenHover, transform: "scale(1.02)"}} borderRadius="10px" bg={Colors.main.green} overflow="hidden" alignItems="center" pl="20px" pr="20px" pt="10px" pb="10px">
                              <Text color={"white"} fontWeight="bold" fontSize="md">{props.buttonText}</Text>
                          </Flex>
                      </Flex>
                  </Link>
                  </a>
                </Box>
            </Flex>

        </Box>
      </Center>
    );
  }