import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton } from "@chakra-ui/react";

import background from '../../images/background_2.jpg'

export default function NavBar(props) {
    return (
        <Image
            w={'780px'}
            h={'100%'}
            src={background}
            objectFit="cover"
        />
  );
}