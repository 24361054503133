import * as React from "react";
import { ChakraProvider, useToast, Button, Box, Flex, Text } from '@chakra-ui/react';
import Colors from "../constants/Colors";

const BotonEnviarMail = ({...props}) => {
    const toast = useToast()
    const toastIdRef = React.useRef()

    function _handleSendMessage(){
        toastIdRef.current = toast({
            title: props.loading_alert_title,
            description: props.loading_alert_description,
            status: 'info',
            duration: 9000,
            isClosable: true,
        })
        fetch('https://reeducacio.netlify.app/.netlify/functions/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain'
            },
            body: JSON.stringify({name: props.name, email: props.email, message: props.message})
        }).then(response =>{
            console.log(response)
            if(response.status == 200){
                toast.close(toastIdRef.current);
                toastIdRef.current = toast({
                    title: props.success_alert_title,
                    description: props.success_alert_description,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            } else{
                toast.close(toastIdRef.current);
                toastIdRef.current = toast({
                    title: props.error_alert_title,
                    description: props.error_alert_description,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        });
    }

    return (
        <Box d="flex" justifyContent="center" mt="36px">
            <Flex as="button" onClick={() => _handleSendMessage()} bg="rgba(255,255,255,1)" direction="row" transition="all 0.5s cubic-bezier(.08,.52,.52,1)" _hover={{transform: "scale(1.02)"}} borderRadius="5px" overflow="hidden" alignItems="center" pl="20px" pr="20px" pt="10px" pb="10px">
                <Text color={Colors.text.title} fontWeight="bold" fontSize="md">{props.button_text}</Text>
            </Flex>
        </Box>
    )
};

export default BotonEnviarMail;