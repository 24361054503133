import {
    Box,
    Center,
    useColorModeValue,
    Heading,
    Text,
    Stack,
    Image,
    Flex,
  } from '@chakra-ui/react';

  import Colors from '../constants/Colors';
  import { HashLink } from 'react-router-hash-link';
  import { AiFillCaretRight, AiOutlineArrowRight } from "react-icons/ai";
  import { MdGroups } from "react-icons/md";
  
  const IMAGE =
    'https://images.unsplash.com/photo-1518051870910-a46e30d9db16?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80';
  
  export default function ProductSimple({...props}) {
    return (
      <Center py={6}>
        <Box
          mr={props.mr}
          role={'group'}
          p={6}
          maxW={'400px'}
          h="full"
          w={'full'}
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow={'xl'}
          _hover={{boxShadow: '2xl', transform: "scale(1.01)"}}
          rounded={'2xl'}
          pos={'relative'}
          zIndex={1}
          transition="all 0.5s cubic-bezier(.08,.52,.52,1)">
          
            <Flex direction="column" alignItems="center" pt="30px" pb="30px" pl="10px" pr="10px">
                
                
                <Flex alignItems="center">
                  {/*<MdGroups size="30px"/>*/}
                  <Heading as="h1" size="lg" ml="12px" mr="12px" color={Colors.main.green}>{props.title}</Heading>
                </Flex>
                <Text fontSize="md" mt="10px" color={Colors.text.subtitle} textAlign="center">{props.description}</Text>
                


                <Flex direction="column" alignItems="left" width="full">
                  <Flex direction="row" alignItems='left' mt="60px">
                      <Box mt="2px" mr="20px">
                          <AiOutlineArrowRight size={22} color={Colors.main.green}/>
                      </Box>
                      <Text fontSize="md" color={Colors.text.subtitle}>{props.item1}</Text>
                  </Flex>
                  <Flex direction="row" alignItems='left' mt="30px">
                      <Box mt="2px" mr="20px">
                          <AiOutlineArrowRight size={22} color={Colors.main.green}/>
                      </Box>
                      <Text fontSize="md" color={Colors.text.subtitle}>{props.item2}</Text>
                  </Flex>
                  <Flex direction="row" alignItems='left' mt="30px">
                      <Box mt="2px" mr="20px">
                          <AiOutlineArrowRight size={22} color={Colors.main.green}/>
                      </Box>
                      <Text fontSize="md" color={Colors.text.subtitle}>{props.item3}</Text>
                  </Flex>
                  <Flex direction="row" alignItems='left' mt="30px">
                      <Box mt="2px" mr="20px">
                          <AiOutlineArrowRight size={22} color={Colors.main.green}/>
                      </Box>
                      <Text fontSize="md" color={Colors.text.subtitle}>{props.item4}</Text>
                  </Flex>
                </Flex>



                <Box direction="row" mt="60px">
                  <Flex direction="row">
                      <Flex direction="row" borderRadius="10px" bg={"white"} borderWidth="1px" borderColor={Colors.main.green} overflow="hidden" alignItems="center" pl="20px" pr="20px" pt="10px" pb="10px">
                          <Text color={Colors.main.green} fontWeight="bold" fontSize="md">{props.time}</Text>
                      </Flex>
                  </Flex>
                </Box>



            </Flex>

        </Box>
      </Center>
    );
  }