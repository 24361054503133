import { initReactI18next } from "react-i18next"
import i18n from "i18next"
//import Backend from 'i18next-http-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';
import LanguageDetector from 'i18next-browser-languagedetector';

import translation_esp from './translations/esp/translation.json'
import translation_eng from './translations/eng/translation.json'
import translation_cat from './translations/cat/translation.json'

i18n
  //.use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: 'cat',
    debug: true,
    interpolation: {
      escapeValue: false
    },
    //lng: "en",
    resources: {
      esp:{
        translation: translation_esp
      },
      eng:{
        translation: translation_eng
      },
      cat: {
        translation: translation_cat
      }
    }
    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    /*
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    }
    */
});
export default i18n;