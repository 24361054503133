import React from 'react';
import {ChakraProvider, Box, Text, Link, VStack, Code, Grid, Heading, Container, Flex, Center, Wrap, WrapItem, Image, Spacer, extendTheme} from '@chakra-ui/react';

import { createBreakpoints } from "@chakra-ui/theme-tools"

import NavBar from '../components/NavBar/NewNavBar'
import MailFormBox from '../components/MailFormBox';
import MailFormBox2 from '../components/MailFormBox2';
import HeaderBox from '../components/HeaderBox';
import BlankBox from '../components/BlankBox';
import FooterBar from '../components/FooterBar'
import EquipBox from '../components/EquipBox'
import ServeisBox from '../components/ServeisBox'
import DiagnosticBox from '../components/DiagnosticBox'
import TractamentBox from '../components/TractamentBox'
import ScrollToTopButton from '../components/NavBar/ScrollToTopButton';
import MapBox from '../components/MapBox';

const breakpoints = createBreakpoints({
	sm: "30em",
	md: "48em",
	lg: "62em",
	"2lg": "1080px",
	"3lg": "1140px",
	xl: "80em",
	"2xl": "96em",
})

const theme = extendTheme({ breakpoints })

function HomeScreen() {
	return (
		<ChakraProvider theme={theme} bg="transparent">
			<VStack>
				<Container maxW="100vw" paddingLeft="0px" paddingRight="0px">
					<NavBar/>
					<section id="header">
						<HeaderBox/>
					</section>
					<section id="serveis">
						<ServeisBox/>
					</section>
					<section id="diagnostic">
						<DiagnosticBox/>
					</section>
					<section id="tractament">
						<TractamentBox/>
					</section>
					<section id="equip">
						<EquipBox/>
					</section>
                    <section id="contacte">
					    <MailFormBox/>
						<MapBox/>
                    </section>
					<FooterBar/>
					<ScrollToTopButton/>
				</Container>
			</VStack>
		</ChakraProvider>
	);
}

export default HomeScreen;