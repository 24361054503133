import React, { Component } from 'react';
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton, Input, Textarea, Wrap, WrapItem, useDisclosure, Stack, Collapse } from "@chakra-ui/react";
import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
} from '@chakra-ui/icons';

import {Link} from "react-scroll";
import logo from '../../images/logo_green.png';

import Colors from '../../constants/Colors';

import IdiomaSelector from './IdiomaSelector';
import { withTranslation } from 'react-i18next';
import i18n from "../../i18n"

const MAIN_COLOR = "#f08e91";

class NavBar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isOpen: false,
		}
	};

	_onToogle() {
		this.setState({isOpen: !this.state.isOpen})
	}

	render() {

		var NAV_ITEMS = [
			{
				label: this.props.t('navBar.inicio'),
				href: 'header',
			},
			{
				label: this.props.t('navBar.servicios'),
				href: 'serveis',
			},
			{
				label: this.props.t('navBar.equipo'),
				href: 'equip',
			},
			{
				label: this.props.t('navBar.contacto'),
				href: 'contacte',
			},
		];

		return (
			<Box zIndex={2} overflow="hidden" bg="white" maxW="100%" h={{base: '80px', md: '100px'}} position={{base: 'fixed', md: "unset"}}>
				<Box maxW="100%" h={"full"} d="flex" borderBottomWidth="1px" justifyContent="center" pl={{base: "16px", md: "36px"}} pr={{base: "16px", md: "36px"}}>
					<Box d="flex" w="1140px" h="full" alignItems="center">
						<Box d="flex">
							<Image
								minW={{base: 43, md: 53}}
								h={{base: 43, md: 53}}
								src={logo}
								objectFit="cover"
							/>
						</Box>
						<Spacer/>
						<Box d="flex" display={{base: 'none', md: 'flex'}}>
							{NAV_ITEMS.map((navItem, index) => {
								return(
									<Box ml={index != 0 ? "70px" : "0px"}>
										<a href="#">
											<Link smooth to={navItem.href ?? ''}>
												<Text fontSize="md" mt="2px" fontWeight={400} transition="all 0.2s cubic-bezier(.08,.52,.52,1)" _hover={{textDecoration: 'none', color: Colors.main.green}} color={Colors.text.title}>{navItem.label}</Text>
											</Link>
										</a>
									</Box>
								)
							})}
							<Box ml="50px">
								<IdiomaSelector/>
							</Box>
						</Box>
						<Flex
							display={{ base: 'flex', md: 'none' }}
							alignItems={"center"}
						>
							<IconButton
								onClick={() => this._onToogle()}
								icon={this.state.isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
								variant={'outline'}
								aria-label={'Toggle Navigation'}
							/>
						</Flex>
					</Box>
				</Box>
				<Collapse in={this.state.isOpen} animateOpacity>
					<Stack
						w="100vw"
						top={'80px'}
						position={{base: 'fixed'}} 
						bg="white"
						borderBottomWidth="1px"
						zIndex={1}
						p={4}
					>
						{NAV_ITEMS.map((navItem) => (
							<Stack
								py={2}
								spacing={4} 
								as={Link}
								href={navItem.href ?? '#'}
								justify={'space-between'}
								align={'left'}
								_hover={{
									textDecoration: 'none',
								}}
								onClick={() => this._onToogle()}
							>
								<a href="#">
									<Link smooth to={navItem.href ?? ''} spy={true} offset={-80}>
										<Text color={"gray.600"} fontWeight={600} _hover={{textDecoration: 'none', color: Colors.main.green}}>{navItem.label}</Text>
									</Link>
								</a>
							</Stack>
						))}
					</Stack>
				</Collapse>

				<Collapse in={this.state.isOpen} animateOpacity>
					<Link style={{position: 'fixed', bottom: 32, left: 32}} zIndex={2}>
						<IdiomaSelector bg={"white"} shadow={"md"} ml="0px"/>
					</Link>
				</Collapse>
			</Box>
		);
	}
}

export default  withTranslation()(NavBar);
