import React, { Component } from 'react';
import {Box, Text, Link, Flex} from '@chakra-ui/react';
import Colors from '../../constants/Colors';
import {ArrowUpIcon} from '@chakra-ui/icons';
import { animateScroll as scroll } from 'react-scroll'


class ScrollToTopButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
        }
    };

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    listener(){
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            this.setState({visible: true})
        } 
        else if (scrolled <= 300){
            this.setState({visible: false})
        }
    }

    handleScroll(evento) {
        let scrollTop = document.documentElement.scrollTop;
    
        if (scrollTop > 300){
            this.setState({visible: true})
        } 
        else if (scrollTop <= 300){
            this.setState({visible: false})
        }
    }

    onClick(){
        scroll.scrollToTop();
    }

    render() {
        return (
            <Link w="50px" h="55px" onClick={() => this.onClick()} bg={"white"} position={"fixed"} bottom={{base: 8, md: 16}} right={{base: 8, md: 16}} borderRadius={"15px"} shadow="md" _hover={{shadow: "xl"}} style={{display: this.state.visible ? 'inline' : 'none'}} transition="all 0.2s cubic-bezier(.08,.52,.52,1)" zIndex={2}>
                <Flex alignItems="center" w={"100%"} h={"100%"} justifyContent="center">
                    <ArrowUpIcon color={Colors.main.green}/>
                </Flex>
            </Link>
        );
    }
}

export default ScrollToTopButton;
