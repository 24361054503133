import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton, Input, Textarea, Wrap, WrapItem, Heading, HashLink, Divider } from "@chakra-ui/react";
import Colors from "../constants/Colors";

import Background1 from "./Backgrounds/Background1"

import { AiFillCaretRight } from "react-icons/ai";

import { withTranslation } from 'react-i18next';

class HeaderBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            company: "",
            message: ""
        }
    };

    _handleSendMessage() {
        console.log("click3")
    }

    render() {
        return (
            <Box maxW="100%" overflow="hidden" d="flex" justifyContent="center">
                <Box w="1140px" p="16px" py={"32px"} bg="">
                    <Flex direction={{base: "column", md: "row"}}  alignItems="left"  mt="60px">
                        <Flex direction="column" w="full" mr="72px" bg="" alignItems="left">
                            <Flex direction="column" mb="50px">
                                <Heading as="h1" size="xl" mr="12px" color={Colors.text.title}>{this.props.t('diagnostic.title')}</Heading>
                                <Text fontSize="lg" mt="10px" color={Colors.text.subtitle}>{this.props.t('diagnostic.description')}</Text>
                            </Flex>
                            <Flex direction="row" alignItems='left'>
                                <Box mt="2px" mr="20px">
                                    <AiFillCaretRight size={22} color={Colors.main.green}/>
                                </Box>
                                <Text fontSize="md" color={Colors.text.subtitle}>{this.props.t('diagnostic.text1')}</Text>
                            </Flex>
                            <Flex mt="30px">
                                <Box mt="2px" mr="20px">
                                    <AiFillCaretRight size={22} color={Colors.main.green}/>
                                </Box>
                                <Text fontSize="md" color={Colors.text.subtitle}>{this.props.t('diagnostic.text2')}</Text>
                            </Flex>
                            <Flex mt="30px">
                                <Box mt="2px" mr="20px">
                                    <AiFillCaretRight size={22} color={Colors.main.green}/>
                                </Box>
                                <Text fontSize="md" color={Colors.text.subtitle}>{this.props.t('diagnostic.text22')}</Text>
                            </Flex>
                            <Flex mt="30px">
                                <Box mt="2px" mr="20px">
                                    <AiFillCaretRight size={22} color={Colors.main.green}/>
                                </Box>
                                <Text fontSize="md" color={Colors.text.subtitle}>{this.props.t('diagnostic.text3')}</Text>
                            </Flex>
                            <Flex mt="30px">
                                <Box mt="2px" mr="20px">
                                    <AiFillCaretRight size={22} color={Colors.main.green}/>
                                </Box>
                                <Text fontSize="md" color={Colors.text.subtitle}>{this.props.t('diagnostic.text4')}</Text>
                            </Flex>
                        </Flex>
                        <Box h="'100%'" pt="55px">
                            <Background1/>
                        </Box>
                    </Flex>
                    <Flex direction="column" mt="80px" alignItems="center">
                        <Divider w="75%"/>
                        <Heading as="h1" fontWeight={"regular"} size="lg" textAlign="center" mr="12px" mt="30px" mb="30px" color={Colors.text.title}>{this.props.t('diagnostic.frase')}</Heading>
                        <Divider  w="75%"/>
                    </Flex>

                </Box>
            </Box>
        );
    }
}
export default withTranslation()(HeaderBox);
