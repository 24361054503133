import { useState, useEffect } from 'react';
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton, Input, Textarea, Wrap, WrapItem , Heading, Center, Stack} from "@chakra-ui/react";

import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

//Images and Icons
import { FiUser, FiPhone, FiMessageSquare, FiTwitter } from "react-icons/fi"
import { IoMailOpenOutline } from "react-icons/io5"
import { HiOutlineMailOpen, HiOutlineLocationMarker, HiOutlinePhone } from "react-icons/hi"
import { FaRegUser } from "react-icons/fa"
import { BiBookmark } from "react-icons/bi"
import { BsInstagram } from "react-icons/bs"
import { AiOutlineWhatsApp } from "react-icons/ai"

import Colors from "../constants/Colors";

const SOCIAL = [
    {
        text: "Plaça de Joaquim Folguera, 5, entl 7",
        text2: "08022 Barcelona",
        icon: <Flex mt={"6px"}><HiOutlineLocationMarker color={Colors.main.green} size={"44px"}/></Flex>,
        link: 'https://maps.google.com/maps?ll=41.405407,2.138895&z=16&t=m&hl=es&gl=US&mapclient=embed&cid=7328858407829260051'
    },
    {
        text: '607 53 59 83',
        text2: "680 33 35 16",
        icon: <Flex mt={"8px"} mr={"10px"}><HiOutlinePhone color={Colors.main.green} size={"40px"}/></Flex>,
        icon2: <Flex mt={"6px"}><AiOutlineWhatsApp color={Colors.main.green} size={"44px"}/></Flex>,
        link: 'whatsapp://send?phone=34607535983'
    },
    {
        text: 'info@reeducacio.com',
        icon: <HiOutlineMailOpen color={Colors.main.green} size={"50px"}/>,
        link: 'mailto:info@reeducacio.com'
    }
];

function SocialBox({...props}) {
    return(
        <Flex flex={1} as="button" direction={"column"} p={"16px"} borderRadius={"16px"} borderWidth={1} onClick={() => window.open(props.link,'_blank')} _hover={{bg: "gray.50", transform: "scale(1.01)"}} style={{webkitTapHighlightColor: "transparent"}} transition="all .6s ease">
            <Flex>
                {props.icon}
                {props.icon2 ?? null}
            </Flex>
            <Text color={Colors.text.title} fontFamily={"Montserrat"} fontWeight={700} mt={"16px"} textAlign={"left"}>{props.text}</Text>
            {props.text2 ? <Text color={Colors.text.title} fontFamily={"Montserrat"} fontWeight={700} textAlign={"left"}>{props.text2}</Text> : null}
        </Flex>
    )
}

export default function MapBox({...props}) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState();

    useEffect(() => {
    }, []);

    return (
        <Box maxW="100%" overflow="hidden" d="flex" justifyContent="center" mb="20px" mt="20px">
            <Box w="1140px" p="16px" py={"32px"}>
                <Flex direction="column" alignItems='center'>
                    <Heading as="h1" size="xl" color={Colors.text.title} textAlign="center" mb="40px">{t('maps.title')}</Heading>
                    {/*<Text fontSize="xl" mt="10px" mb="40px" color={Colors.text.subtitle} maxW="700px" textAlign="center">{t('equip.description')}</Text>*/}
                </Flex>
                <Box overflow={"hidden"}>
                    {/*<MapWithAMarker
                        isMarkerShown
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBdRRn1vQaH9Fk0n7sxDZQ2evByN0M52bI&v=3.exp&libraries=geometry,drawing,places"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `400px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />*/}
                    <Flex direction={{base: "column", md: "row"}} p={"1.5px"}>
                        <Flex flex={1} borderRadius={"20px"} overflow={"hidden"}>
                            <Flex flex={1} height={{base: 400, md: "100%"}}>
                                <iframe src="https://maps.google.com/maps?width=100%25&amp;height=100%&amp;hl=es&amp;q=Pla%C3%A7a%20de%20Joaquim%20Folguera,%205,%20Entl%207,%2008022%20Barcelona+(Reeducaci%C3%B3%20i%20aprenentatge)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width={"100%"} height={"100%"} allowfullscreen></iframe>
                            </Flex>
                        </Flex>
                        <Box w={{base: "full", md: 340}} ml={{base: "0px", md: "20px"}} mt={{base: "20px", md: "0px"}} borderRadius={"20px"}>
                            <Flex direction={"column"} flex={1} justifyContent={"center"}>
                                <Stack spacing={{base: "16px", md: "16px"}}>
                                    <Stack direction={"row"} spacing={{base: "16px", md: "16px"}}>
                                        <SocialBox text={SOCIAL[0].text} text2={SOCIAL[0].text2} icon={SOCIAL[0].icon} link={SOCIAL[0].link}/>
                                    </Stack>
                                    <Stack direction={"row"} spacing={{base: "16px", md: "16px"}}>
                                        <SocialBox text={SOCIAL[1].text} text2={SOCIAL[1].text2} icon={SOCIAL[1].icon} icon2={SOCIAL[1].icon2} link={SOCIAL[1].link}/>
                                    </Stack>
                                    <Stack direction={"row"} spacing={{base: "16px", md: "16px"}}>
                                        <SocialBox text={SOCIAL[2].text} icon={SOCIAL[2].icon} link={SOCIAL[2].link}/>
                                    </Stack>
                                </Stack>
                            </Flex>
                        </Box>
                    </Flex>
                </Box>

            </Box>
        </Box>
    );
};
